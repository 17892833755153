.login {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-top: 8px;
}

.header_div {
  margin-top: 12px;
  margin-bottom: 24px;
  margin-right: 46px;
}
.header {
  font-size: 40px;
  font-weight: 100;
  margin-bottom: 8px;
}

.container {
  width:90%;
  text-align:center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-top: 8px;
}

.leftbox {
  float: left;
  width: 30%;
  margin-right: 36px;
}

.center {
  display: inline-block;
  width:30%;
}

.rightbox {
  float: right;
  width: 30%;
  margin-left: 36px;
}

.button_div {
    margin-bottom: 36px;
}

.img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid black
}
