.input {
  align-items: center;
}
.input label {
  height: 20px;
  margin-bottom: 4px;
  margin-top: 18px;
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  font-weight: bold;
  margin-right: 1rem;
}

.label_div {
  text-align: start;
}
.input input {
  border-radius: 5px;
  border: 1px solid #ccc;
  font: inherit;
  padding-left: 0.5rem;
  height: 40px;
  width: 360px;
}

select.dropdown {
  border-radius: 5px;
  align-items: center;
  height: 36px;
  width: 360px;
  font: inherit;
  padding-left: 0.5rem;
  border: 1px solid #ccc;
}

.error_text {
  color: #f82222;
  font-size: 12px;
  font-weight: bold;
}
