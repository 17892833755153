.login {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-top: 20px;
}

.header_div {
  margin-top: 46px;
  margin-bottom: 24px;
  margin-right: 46px;
}

.header {
  font-size: 40px;
  font-weight: 100;
  margin-bottom: 8px;
}

.signup {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-top: 16px;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
}
