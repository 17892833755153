.starting {
    margin: 3rem auto;
    text-align: center;
  }
  
  .starting h1 {
    font-size: 5rem;
  }

  .bg_color0 {background-color: #ffffff;}

  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .row:before,
  .row:after {
    display: table;
    content: " ";
  }
  
  .row:after {
    clear: both;
  }
  
  .row:before,
  .row:after {
    display: table;
    content: " ";
  }
  
  .row:after {
    clear: both;
  }