.error_center{
    text-align: center;
    padding: 12px;
    visibility: visible;
}
.error_normal{
    visibility: visible;
}
.error_text {
    color: #f82222;
    font-size: 12px;
    font-weight: bold;
  }