.mainbox {
    width: 100%;
    height: 100%;
    position: absolute;
}
.leftbox {
    float: left;
    width: 5%;
    height: 100%;
    background-color: #039EE1;
    position: absolute;
  }
  
  .rightbox {
    float: right;
    height: 100%;
    width: 95%;
  }

  .image {
      width: 24px;
      height: 24px;
      display: block;
  }
  .imageDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 36px;
    padding-bottom: 36px;
  }