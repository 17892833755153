.login {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin-top: 8px;
  }
  
  .header_div {
    margin-top: 12px;
    margin-bottom: 24px;
    margin-right: 46px;
  }
  .header {
    font-size: 40px;
    font-weight: 100;
    margin-bottom: 8px;
  }
  .leftbox {
    float: left;
    width: 50%;
    padding-right: 36px;
  }
  
  .rightbox {
    float: right;
    width: 50%;
    padding-left: 36px;
  }
  
  .button_div {
      margin-bottom: 36px;
  }
  