.button {
    font: inherit;
    border: 1px solid #039EE1;
    background: #039EE1;
    color: white;
    padding: 0.75rem 3.5rem;
    cursor: pointer;
    font-size: 1.15rem;
    border-radius: 30px;
    margin-top: 18px;
    width: 360px;
  }
  
  .button:hover,
  .button:active {
    background: #034e6e;
    border-color: #034e6e;
  }
  
  .button:focus {
    outline: none;
  }
  
  button:disabled,
  button:focus:disabled,
  button:hover:disabled,
  button:active:disabled {
    background: #ccc;
    border-color: #ccc;
    color: #666666;
    cursor: not-allowed;
  }
  